import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MultiSlider from '../../components/MultiSlider';

const Labs = ({ data }) => {
    return (
        <div className="w-full">
            <div className="header w-full rounded bg-primary text-white flex flex-row items-center space-x-2">
                {data.icon ? <FontAwesomeIcon icon={['fas', data.icon]} className="text-white px-2" /> : null}
                <p className="py-1">{data.title}</p>
            </div><br />
            <div className="body space-y-6">
                {
                    data.attribute.map((attr, i) => (
                        <div className="flex flex-col space-y-1" key={i}>
                            <p>{attr.testName}</p>
                            <MultiSlider min={attr.min} max={attr.max} range={attr.colorRange} marks={attr.details} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Labs;
