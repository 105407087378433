/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faPlus } from '@fortawesome/free-solid-svg-icons';
import CardSet from './CardSet';
import CustomCardSet from './CustomCardset';
import { ACTION } from '../../store/hhFramework/action';
import { Formik, Form } from 'formik';
import FormikControl from '../../components/formik/FormikControl';
// import { setHours, setMinutes } from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { axiosPublic } from '../../api';

const HerringboneFramework = () => {
    const [showQuestionnaire, setShowQuestionnaire] = useState(false);
    const [questionList, setQuestionList] = useState();
    const { patient } = useSelector(state => state.patient);
    const { provider } = useSelector(state => state.provider);
    const { mainboard } = useSelector(state => state.hhFrameworkCardset);
    const state = useSelector(state => state.hhFrameworkCardset);

    const questionnaireInitValues = {
        date: new Date(),
        questionnaireId: ''
    };

    const dispatch = useDispatch();

    const { provider_id } = provider;
    const { patient_id } = patient;

    useEffect(() => {
        axiosPublic.get('/hb-health-framework/interventions/questionnaire/questionnaire-master')
            .then(res => {
                const quesArr = [];
                for (const ques of res.data.availableQuestionnaireList) {
                    quesArr.push({
                        value: ques.questionnaireId,
                        label: ques.questionnaireTitle
                    });
                }
                setQuestionList(quesArr);
            });
    }, []);

    useEffect(() => {
        if (patient_id && provider_id) {
            dispatch({
                type: ACTION.SYNC_MAINBOARD
            });
            dispatch({
                type: ACTION.SYNC_STATUS_TEMPLATE
            });
        }
    }, [patient_id, provider_id]);

    const handleChildClose = () => {
        dispatch({
            type: ACTION.TRIM_PATH,
            payload: {
                state,
                _fromIndex: 0
            }
        });
    };

    const toggleQuestionnaire = () => {
        setShowQuestionnaire(prev => !prev);
    };

    const handleSendQuestionnaire = (payload) => {
        axiosPublic.post('/hb-health-framework/interventions/questionnaire/trigger-questionnaire', payload)
            .then(res => {
                setShowQuestionnaire(false);
            });
    };

    return (
        <>
            {
                !state.pathDepth
                    ? <div>
                        <div className='flex flex-row items-center space-x-4'>
                            <p className='text-lg font-medium'>Herringbone Health Framework</p>
                            <div onClick={toggleQuestionnaire}>
                                <FontAwesomeIcon icon={faPlus} className="text-black" />
                            </div>
                            {
                                showQuestionnaire
                                    ? <Formik
                                        initialValues={questionnaireInitValues}
                                        // validationSchema={validationSchema}
                                        onSubmit={(values, actions) => {
                                            // handleAddBg(values.startDate, values.readingSetType)
                                            handleSendQuestionnaire({
                                                date: values.date,
                                                patientId: patient_id,
                                                providerId: provider_id,
                                                questionnaireId: values.questionnaireId
                                            });
                                        }}
                                    >
                                        {({ isSubmitting, submitForm, values }) => (
                                            <Form className='flex flex-row justify-end space-x-1 items-center w-fit'>
                                                <div className="cursor-pointer flex justify-end">
                                                    <FormikControl
                                                        key='date'
                                                        className=""
                                                        control='date'
                                                        name='date'
                                                        minDate={new Date()}
                                                        showTimeSelect
                                                        // includeTimes={[
                                                        //     new Date().setHours(new Date().setMinutes(new Date(), 0), 17),
                                                        //     new Date().setHours(new Date().setMinutes(new Date(), 30), 18),
                                                        //     new Date().setHours(new Date().setMinutes(new Date(), 30), 19),
                                                        //     new Date().setHours(new Date().setMinutes(new Date(), 30), 17)
                                                        // ]}
                                                        minTime={setMinutes(new Date(), 0)}
                                                        maxTime={setHours(setMinutes(new Date(values.date), 59), 23)}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        autoComplete="off" />
                                                </div>
                                                <div className='flex'>
                                                    <FormikControl control='select' name='questionnaireId' options={questionList} defaultValue="Select Question" />
                                                </div>
                                                <div className="border-2 border-[#E8E8E8] px-4 py-2 rounded bg-primary text-white rounded" onClick={() => submitForm()}>
                                                    Submit
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    : null
                            }
                        </div>
                        <div className='flex flex-no-wrap h-fit overflow-x-scroll scrolling-touch items-start p-4'>
                            {
                                mainboard
                                    ? mainboard.cardsetCardDetails.map((cardSetData, i) => <CardSet key={i} cardSet={cardSetData} cardSetIndex={i} patientId={patient_id} />)
                                    : null
                            }
                        </div>
                    </div>
                    : <div>
                        <div className="flex flex-row justify-between">
                            <p className='text-lg font-medium'>Herringbone Health Framework</p>
                            <div onClick={handleChildClose} className="cursor-pointer mr-6">
                                <FontAwesomeIcon icon={faArrowLeftLong} className="text-black" />
                            </div>
                        </div>
                        <div className='flex flex-no-wrap h-fit overflow-x-scroll scrolling-touch items-start p-4'>
                            {state.activeArray.map((cardSetData, i) => {
                                return <CustomCardSet key={i} cardSet={cardSetData} cardsetIndex={i} />;
                            })}
                        </div>
                    </div>
            }
        </>
    );
};

export default HerringboneFramework;
