export const ACTION = {
    INITIATE_PATH: 'INITIATE_PATH',
    INITIATE_MAIN: 'INITIATE_MAIN',
    INITIATE_STATE: 'INITIATE_STATE',
    INITIATE_STATUS_TEMPLATE: 'INITIATE_STATUS_TEMPLATE',
    SYNC_MAINBOARD: 'SYNC_MAINBOARD',
    SYNC_STATUS_TEMPLATE: 'SYNC_STATUS_TEMPLATE',
    EXPAND_PATH: 'EXPAND_PATH',
    TRIM_PATH: 'TRIM_PATH',
    UPDATE_STATE: 'UPDATE_STATE',
    UPDATE_STATUS: 'UPDATE_STATUS',
    UPDATE_TREE_STATUS: 'UPDATE_TREE_STATUS',
    UPDATE_INTERVENTION: 'UPDATE_INTERVENTION',
    UPDATE_INTERVENTION_TRANS_STATUS: 'UPDATE_INTERVENTION_TRANS_STATUS'
};
