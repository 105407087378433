import { ACTION } from './action';

const initialState = {
    tree: null,
    activePath: [],
    activeArray: [],
    pathDepth: 0,
    cardsetId: 0,
    mainboard: null,
    statusTemplate: null,
    interventionTransStatus: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION.INITIATE_STATE:
        return {
            ...state,
            ...action.payload.state
        };
    case ACTION.UPDATE_STATE:
        return { ...state, ...action.payload.state };
    case ACTION.UPDATE_TREE_STATUS:
        state.tree[action.payload.id].status = action.payload.status;
        return { ...state };
    case ACTION.INITIATE_MAIN:
        state.mainboard = action.payload;
        return { ...state };
    case ACTION.INITIATE_STATUS_TEMPLATE:
        state.statusTemplate = action.payload;
        return { ...state };
    case ACTION.UPDATE_INTERVENTION_TRANS_STATUS:
        state.interventionTransStatus = action.payload.interventionStatus;
        return {
            ...state
        };
    default:
        return state;
    }
};
