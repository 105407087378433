import React from 'react';
import BrandLogo from '../../assets/images/brand-logo.png';

const Navbar = ({ patientName }) => {
    return (
        <nav className="w-full shadow">
            <div className="w-full flex flex-row">
                <div className="w-44 flex items-center py-3">
                    {/* <a href="javascript:void(0)"> */}
                    <img src={BrandLogo} alt="brand-logo" />
                    {/* </a> */}
                </div>
                <div className="w-full py-3 md:py-3 absolute flex justify-center self-center ml-4 sm:ml-0">
                    {/* <p className='text-md sm:text-lg md:text-3xl text-secondary'><span className='text-primary'>Herringbone</span> Diabetic Health Care</p> */}
                    <p className='text-md sm:text-lg md:text-3xl text-primary'>{ patientName }</p>
                </div>

                {/* <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 block`}
                    />
                </div> */}
            </div>
        </nav>
    );
};

export default Navbar;
