import React, { useEffect, useState } from 'react';
import Labs from './Labs';
import Behavior from './Behavior';
import Event from './Event';
import { axiosPublic } from '../../api';

const RiskScoreBoard = ({ patientId }) => {
    const [riskScore, setRiskScore] = useState(null);
    useEffect(() => {
        if (patientId) {
            axiosPublic.get(`/risk-scoreboard/${patientId}`)
                .then(res => {
                    setRiskScore(res.data);
                });
        }
    }, [patientId]);

    return (
        <>
            {riskScore
                ? <div className='max-h-80 flex flex-col xl:flex-row p-4 gap-x-4 gap-y-16 overflow-scroll justify-items-center pb-20'>
                    <Labs data={riskScore.data.labVitals} />
                    <div className="-ml-0.5 w-0.5 bg-border h-96"></div>
                    <Event data={riskScore.data.riskEvents} />
                    <div className="-ml-0.5 w-0.5 bg-border h-96"></div>
                    <Behavior data={riskScore.data.patientBehavior} />
                </div>
                : null
            }
        </>
    );
};

export default RiskScoreBoard;
