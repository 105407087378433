/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import Card from './CustomCard';
import CardNotes from './CardNotes';
import { ACTION } from '../../store/hhFramework/action';
import { ACTION as HH_TRANS_ACTION } from '../../store/hhFrameworkTransaction/action';
import { getUpdatedStatus } from '../../store/hhFramework/saga';
import PatientDescription from './PatientDescription';
import CardHistory from './CardHistory';
import DotRuler from '../../components/DotRuler';
import Goals from './Goals';
import Resources from './Resources';
import { formatCustomDate } from '../../utils';
import SaveButton from '../../components/SaveButton';
// import showToast from '../../components/ui/Toast';

const CustomCardSet = ({ cardSet, cardsetIndex }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.hhFrameworkCardset);
    const { statusTemplate } = state;
    const [cardset, setCardset] = useState([]);
    const [formTemplate, setFormTemplate] = useState([]);
    const [patientDesc, setPatientDesc] = useState([]);
    const [cardsetDesc, setCardsetDesc] = useState();
    const [rulerSet, setRulerSet] = useState([]);
    const [goals, setGoals] = useState({});
    const [isCardHistory, setIsCardHistory] = useState(false);
    const [resources, setResources] = useState([]);
    const [ruler0Val, setRuler0Val] = useState(0);
    const [ruler1Val, setRuler1Val] = useState(0);
    const [ruler2Val, setRuler2Val] = useState(0);
    const [resetRuler, setResetRuler] = useState(false);

    const apptInitValues = {
        dropdown: '',
        freeText: '',
        resourceIdSent: []
    };

    useEffect(() => {
        // const newInitValues = {
            apptInitValues.dropdown = cardSet.interventionHistory?.goalSelected ? cardSet.interventionHistory.goalSelected.id === null ? -1 : `${cardSet.interventionHistory.goalSelected.id}_${cardSet.interventionHistory.goalSelected.goal}` : '';
            apptInitValues.freeText = cardSet.interventionHistory?.goalSelected?.id === null ? cardSet.interventionHistory.goalSelected.goal : '';
            apptInitValues.resourceIdSent = cardSet.interventionHistory?.resourceSelected ? cardSet.interventionHistory.resourceSelected.map(({ id }) => id) : [];
        // };
    }, [cardSet.id]);

    useMemo(() => {
        setCardset(cardSet.attributes);
        setFormTemplate(cardSet.formTemplate);
        setPatientDesc(cardSet.patientCardDescription);
        setCardsetDesc(cardSet.cardDescription);
        if (cardSet.interventionHistory && cardSet.interventionHistory.rulerScore.length) {
            cardSet.rulerDetails[0].currentValue = cardSet.interventionHistory.rulerScore[0] ? cardSet.interventionHistory.rulerScore[0].rulerValue : 0;
            cardSet.rulerDetails[1].currentValue = cardSet.interventionHistory.rulerScore[1] ? cardSet.interventionHistory.rulerScore[1].rulerValue : 0;
            cardSet.rulerDetails[2].currentValue = cardSet.interventionHistory.rulerScore[2] ? cardSet.interventionHistory.rulerScore[2].rulerValue : 0;
        }
        if (cardSet.rulerDetails) {
            setRulerSet(cardSet.rulerDetails);
        }
        if (cardSet.goalDetails) {
            setGoals(cardSet.goalDetails);
        }
        if (cardSet.availableResourceDetails) {
            setResources(cardSet.availableResourceDetails);
        }
    }, [cardSet.id, cardSet.formTemplate]);

    const cardsetCloseHandler = () => {
        dispatch({
            type: ACTION.TRIM_PATH,
            payload: {
                state,
                _fromIndex: cardsetIndex
            }
        });
    };

    const statusUpdateHandler = (cardIndex, cardId, status) => {
        if (cardset[cardIndex].id === cardId) {
            const statusObj = getUpdatedStatus(status, statusTemplate);
            cardset[cardIndex].status = statusObj.id;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleResetRuler = () => {
        setResetRuler(prev => !prev);
    };

    const cardHistoryHandler = () => {
        setIsCardHistory(prev => !prev);
    };

    return (
        <div className='flex flex-col w-2/5 max-w-md p-4 mb-4'>
            {isCardHistory ? <CardHistory cardsetId={cardSet.id.split('-')[0]} cardId={cardSet.id.split('-')[1]} isModalOpen={isCardHistory} toggleModal={cardHistoryHandler} /> : null}
            <div className='p-4 bg-primary rounded-t-lg flex flex-row justify-between'>
                <div className='flex flex-row items-center'>
                    <p className="text-md font-bold text-white">{cardSet.name}</p>
                </div>
                <div className='flex flex-row'>
                    <div onClick={cardHistoryHandler}>
                        <FontAwesomeIcon icon={faClockRotateLeft} className='text-white p-2 rounded' />
                    </div>
                    <div className='cursor-pointer' onClick={() => cardsetCloseHandler()}>
                        <FontAwesomeIcon icon={faXmark} className='text-white p-2 rounded' />
                    </div>
                </div>
            </div>
            <div className="flex-none w-full h-[30rem] overflow-y-scroll md:pb-4 shadow-md border-2 border-[#E8E8E8] rounded-b-lg ">
                {/* cardset description */}
                {
                    cardsetDesc
                        ? <div className="flex-none w-full overflow-auto md:p-4 shadow-md border-2 border-[#E8E8E8] rounded-b-lg ">
                            <p className='whitespace-pre-wrap font-bold'>Desc:</p>
                            <p>{cardsetDesc}</p>
                        </div>
                        : null
                }
                {/* patient description */}
                {
                    patientDesc?.Data?.length
                        ? <div className="flex-none w-full overflow-auto md:p-4 shadow-md border-2 border-[#E8E8E8] rounded-b-lg ">
                            <PatientDescription descTemplate={patientDesc} cardsetId={cardSet.id} cardsetIndex={cardsetIndex} statePath={state.activePath} />
                        </div>
                        : null
                }
                {/* form template */}
                {
                    formTemplate?.length
                        ? <div className="flex-none w-full overflow-auto md:p-4 shadow-md border-2 border-[#E8E8E8] rounded-b-lg ">
                            <CardNotes template={formTemplate} cardsetId={cardSet.id} cardsetIndex={cardsetIndex} statePath={state.activePath} />
                        </div>
                        : null
                }
                {
                    cardSet.isIntervention
                        ? <div key={cardSet.id}><Formik
                            initialValues={apptInitValues}
                            // validationSchema={validationSchema}
                            onSubmit={(values, actions) => {
                                const packet = {
                                    date: formatCustomDate(new Date(), 'yyyy-mm-dd'),
                                    cardId: cardSet.id,
                                    rulerResponse: [
                                        {
                                            questionId: 1,
                                            providerResponse: ruler0Val
                                        },
                                        {
                                            questionId: 2,
                                            providerResponse: ruler1Val
                                        },
                                        {
                                            questionId: 3,
                                            providerResponse: ruler2Val
                                        }
                                    ],
                                    resourceIdSent: values.resourceIdSent
                                };
                                if (values.dropdown === '') {
                                    packet.goalSet = {
                                        id: '',
                                        goal: ''
                                    };
                                } else {
                                    packet.goalSet = {
                                        id: values.dropdown === -1 ? null : values.dropdown.split('_')[0],
                                        goal: values.dropdown === -1 ? values.freeText : values.dropdown.split('_')[1]
                                    };
                                }
                                dispatch({
                                    type: HH_TRANS_ACTION.SEND_INTERVENTION,
                                    payload: packet
                                });
                                actions.setSubmitting(false);
                                // actions.resetForm();
                                // handleResetRuler();
                                // showToast('Interventions sent successfully', 5000);
                            }}
                        >
                            {({ values, isSubmitting, submitForm }) => (
                                <Form id={cardSet.id} className='flex flex-col items-center w-full'>
                                    <div className='space-y-10 p-4 flex flex-col  border-4'>
                                        {/* <div className='text-lg font-bold p-2'>Interventions</div> */}
                                        {/* custom ruler */}
                                        {
                                            rulerSet.length
                                                ? <div className='space-y-6'>
                                                    <p className='flex justify-self-start p-2'>Readiness review: </p>
                                                    {
                                                        (<><DotRuler key={cardSet.id + rulerSet[0].questionId} data={rulerSet[0]} reset={resetRuler} updateRulerVal={(e) => setRuler0Val(e)} />
                                                            <DotRuler key={cardSet.id + rulerSet[1].questionId} data={rulerSet[1]} reset={resetRuler} updateRulerVal={(e) => setRuler1Val(e)} />
                                                            <DotRuler key={cardSet.id + rulerSet[2].questionId} data={rulerSet[2]} reset={resetRuler} updateRulerVal={(e) => setRuler2Val(e)} /></>)
                                                    }
                                                </div>
                                                : null
                                        }

                                        {/* goals */}
                                        {
                                            Object.keys(goals).length
                                                ? <div>
                                                    <p className='flex justify-self-start p-2'>Goals: </p>
                                                    <Goals cardsetId={cardSet.id} goalsData={goals} formikValues={values} />
                                                </div>
                                                : null
                                        }
                                        {
                                            resources.length
                                                ? (<div>
                                                    <p>Send Resources:</p>
                                                    <Resources cardsetId={cardSet.id} resources={resources} />
                                                </div>)
                                                : null
                                        }
                                        <SaveButton isLoading={state.interventionTransStatus} />
                                    </div>
                                </Form>
                            )}
                        </Formik></div>
                        : null
                }
                <div className='w-[23.5rem] items-center py-4 flex flex-col'>
                    {
                        cardset.map((card, i) =>
                            <div key={`card_${i}`} className="w-full" >
                                <Card id={card.id} cardsetName={cardSet.name} cardsetId={cardSet.id} cardDetails={card} cardsetIndex={cardsetIndex} cardIndex={i} handleStatusUpdate={statusUpdateHandler} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};
export default CustomCardSet;
