import React, { useRef, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import CalendarBoard from '../../continers/CalendarBoard';
import Navbar from '../../continers/Navbar';
import RiskScoreBoard from '../../continers/RiskScoreboard';
import BloodGlucoseBoard from '../../continers/BloodGlucoseBoard';
import HerringboneFramework from '../../continers/HerringboneFramework';

const PatientModule = () => {
    const { patient } = useSelector(state => state.patient);

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const bgChartRef = useRef(null);

    useLayoutEffect(() => {
        if (bgChartRef.current) {
            setDimensions({
                width: bgChartRef.current.offsetWidth * 0.5,
                height: bgChartRef.current.offsetHeight
            });
        }
    }, [patient]);

    return (
        <div className=''>
            {patient
                ? <>
                    <Navbar patientName={patient.patient_name} />
                    <div className='flex flex-col h-screen w-100 mt-8 mx-8'>
                        <div className='space-y-8'>
                            <div className='flex flex-col sm:flex-row justify-between items-center'>
                                <div className='md:w-[48%] w-full h-[24rem] p-2 px-4 shadow-md border-2 border-[#E8E8E8] rounded-lg'>
                                    <div className='max-h-92' ref={bgChartRef} >
                                        <BloodGlucoseBoard id={patient.patient_id} width={dimensions.width} height={dimensions.height} />
                                    </div>
                                </div><br />
                                <div className='w-full sm:w-[50%] h-[24rem] space-y-1 p-4 shadow-md border-2 border-[#E8E8E8] rounded-lg'>
                                    <p className='text-lg font-medium'>Risk Scoreboard</p>
                                    <RiskScoreBoard patientId={patient.patient_id} />
                                </div>
                            </div>
                            <div className='w-full space-y-4 p-4 shadow-md border-2 border-[#E8E8E8] rounded-lg'>
                                <HerringboneFramework />
                            </div>
                            <div className='w-full space-y-4 p-4 shadow-md border-2 border-[#E8E8E8] rounded-lg'>
                                <p className='text-lg font-medium'>Calendar</p>
                                <CalendarBoard />
                            </div>
                        </div>
                    </div>
                </>
                : <div>
                    <p>record not available</p>
                </div>
            }
        </div>
    );
};

export default PatientModule;
