import React, { useState } from 'react';

const SaveButton = ({ isLoading }) => {
    // const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const handleClick = () => {
        // setIsLoading(true);

        // Simulate API call with a timeout
        setTimeout(() => {
            // setIsLoading(false);
            setIsSaved(true);

            // Reset button state after 5 seconds
            setTimeout(() => {
                setIsSaved(false);
            }, 5000);
        }, 2000);
    };

    return (
        <button
            className={`py-2 px-4 bg-blue-500 text-white rounded ${isLoading && 'opacity-50 cursor-wait'}`}
            disabled={isLoading || isSaved}
            onClick={handleClick}
        >
            {isLoading
                ? (
                    <svg
                        className="animate-spin mr-2 h-5 w-5 text-white inline-block"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 018 4.109v3.982c0 .622.24 1.207.673 1.646L8 10.709l2.854-2.853a2 2 0 012.828 0l.708.708a2 2 0 010 2.828L11.999 14.7l1.853 1.853A2 2 0 0112.53 18H5.998zM19.294 8.293a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0l-1-1a1 1 0 011.414-1.414L14 10.586l2.293-2.293a1 1 0 011.414 0z"
                        />
                    </svg>
                )
                : (
                    isSaved ? 'Saved!' : 'Save'
                )}
        </button>
    );
};

export default SaveButton;
