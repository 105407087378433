/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { formatCustomDate } from '../../utils';
// import { ACTION } from '../../store/action';
import FormikControl from '../../components/formik/FormikControl';
import { axiosPublic } from '../../api';

const MoreOption = () => {
    
    const [initBg, setInitBg] = useState(false);

    const apptInitValues = {
        startDate: new Date(),
        readingSetType: 'Basic'
    }
    
    const glucoseSetOptions = [
        { label: 'Basic', value: 'Basic' },
        { label: 'Post-Prandial', value: 'Post-Prandial' },
        { label: 'Super-Set', value: 'Super-Set' },
        { label: 'Fasting Only', value: 'Fasting-Only' }
    ]

    const { patient } = useSelector(state => state.patient);
    const { provider } = useSelector(state => state.provider);
    // const dispatch = useDispatch();

    const handleAddBg = (startDate, type) => {
        // dispatch({
        //     type: ACTION.INITIATE_BG_SESSION,
        //     payload: {
        //         startDate: formatCustomDate(startDate, 'yyyy-mm-dd'),
        //         readingSetType: type,
        //     }
        // });
        const payload = {
            setByProviderId: provider.provider_id,
            startDate: formatCustomDate(startDate, 'yyyy-mm-dd'),
            readingSetType: type,
            patientId: patient.patient_id
        }
        axiosPublic.post('/patient-bg-reading/initiate-bg-set-by-provider', payload)
        .then(res => {
            setInitBg(false);
        })
        .catch(e => {
            console.log('error: ', e);
        })
    }

    const handleInitiateBg = () => {
        setInitBg(true);
    }

    return (
        <div className=''>
            {initBg ?
                <div className='flex flex-row py-1 w-full'>
                    <Formik
                        initialValues={apptInitValues}
                        // validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            handleAddBg(values.startDate, values.readingSetType)
                        }}
                    >
                        {({ isSubmitting, submitForm }) => (
                            <Form className='flex flex-row justify-end space-x-1 items-center'>
                                <div className=''>
                                    {/* <FormikControl control='select' name='readingSetType' options={glucoseSetOptions} defaultValue="Glucose Set Type" /> */}
                                    <FormikControl control='select' name='readingSetType' options={glucoseSetOptions} defaultValue="Select goal" />
                                </div>
                                <div className="cursor-pointer flex justify-end">
                                    <FormikControl key='startDate' className="" control='date' name='startDate' />
                                </div>
                                <div className="border-2 border-[#E8E8E8] px-2 rounded" onClick={() => submitForm()}>
                                    <FontAwesomeIcon icon={faCheck} className='text-sm text-customGray' />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div> :
                <DropDown initBg={handleInitiateBg} />
            }
        </div>
    )
}

const DropDown = ({ initBg }) => {

    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    }

    const handleRestart = () => {
        // dispatch({
        //     type: ACTION.RESTART_SESSION
        // })
        toggleDropdown();
    }

    const handleAbort = () => {
        // dispatch({
        //     type: ACTION.ABORT_SESSION
        // })
        toggleDropdown();
    }

    const handleInitiate = () => {
        initBg();
    }

    return (
        <>
            <div className="group inline-block">
                <div
                    className="outline-none focus:outline-none px-0 py-1 bg-white rounded-sm flex items-center min-w-32"
                    onClick={() => toggleDropdown()}
                >
                    <FontAwesomeIcon icon={faEllipsisVertical} className="text-customGray" />
                </div>
                <ul
                    className={`bg-white border rounded-sm  ${isOpen ? 'scale-100' : 'scale-0'} absolute 
  transition duration-150 ease-in-out origin-top min-w-32 text-xs`}
                >
                    <li className="rounded-sm px-3 py-1 hover:bg-gray-100" onClick={() => { handleInitiate() }}>Initiate set</li>
                    <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-400" >Restart set</li>
                    <li className="rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-400" >Abort set</li>
                </ul>
            </div>

        </>
    )
}

export default MoreOption;
