import React, { useEffect, useState } from 'react';
import FormikControl from '../../components/formik/FormikControl';

const Goals = ({ goalsData, cardsetId, formikValues }) => {
    const [goals, setGoals] = useState();

    useEffect(() => {
        setGoals(goalsData);
    }, [goalsData]);

    return (
        <div id={cardsetId} className='flex w-full flex flex-col'>
            {
                goals
                    ? <><div className='py-2'>
                        {generateGoalType('dropdown', goals.dropdown, cardsetId)}
                    </div>
                    {formikValues?.dropdown === -1
                        ? <div className=''>
                            {generateGoalType('freeText', goals.freeText, cardsetId)}
                        </div>
                        : null}
                    </>
                    : null
            }

        </div>
    );
};

const generateGoalType = (goalType, goal, id) => {
    switch (goalType) {
    case 'dropdown':
        // eslint-disable-next-line no-case-declarations
        const optionsStruct = [{
            label: 'Custom goal',
            value: -1
        }];
        if (goal.values) {
            for (const value of goal.values) {
                optionsStruct.push(
                    {
                        label: value.goal,
                        value: value.goalId + '_' + value.goal
                    }
                );
            }
        }
        return <FormikControl id={id + goalType} control='select' className='w-96' name={goalType} options={optionsStruct} defaultValue="select goal" />;
    case 'freeText':
        return <FormikControl id={id + goalType} control='input' className='w-96 border-2 border-border rounded p-1' name={goalType} defaultValue="Type here..." label="Custom Goal" />;
    default:
        return null;
    }
};

export default Goals;
