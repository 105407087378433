import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { axiosPublic } from '../../api';
import Modal from '../../components/ui/Modal';
import CardNotesHistoryTable from '../../tables/CardNotesHistoryTable';

const CardHistory = ({ isModalOpen, toggleModal, cardsetId, cardId }) => {
    const { patient } = useSelector(state => state.patient);
    const { provider } = useSelector(state => state.provider);

    const [notesHistory, setNotesHistory] = useState([]);

    useEffect(() => {
        axiosPublic.get(`/hb-health-framework/patient-progress/${patient.patient_id}?cardset_id=${cardsetId}&card_id=${cardId}`)
            .then(res => {
                setNotesHistory(res.data.history);
            });
    }, []);

    return (
        <Modal isOpen={isModalOpen} toggleModal={toggleModal} closeOnOutsideClick={true}>
            <div className="flex flex-col p-4 shadow-md border-2 border-[#E8E8E8]">
                <CardNotesHistoryTable provider={provider} notesHistory={notesHistory} />
            </div>
        </Modal>
    );
};

export default CardHistory;
