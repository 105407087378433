import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Textarea = (props) => {
    const { label, name, ...rest } = props;

    return (
        <div className='form-control  w-full'>
            <label htmlFor={name} className='flex justify-self-start'>{label}</label>
            <Field as='textarea' id={name} name={name} className='rounded-md border-2 border-border w-full' {...rest} />
            <ErrorMessage name={name}>
                <TextError />
            </ErrorMessage>
        </div>
    );
};

export default Textarea;
