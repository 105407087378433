/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { ACTION } from '../../store/hhFramework/action';
import { ACTION as HH_TRANS_ACTION } from '../../store/hhFrameworkTransaction/action';
import { getUpdatedStatus } from '../../store/hhFramework/saga';

const Card = ({ cardIndex, cardsetName, cardsetId, cardDetails, id, cardRank, patientId, handleStatusUpdate }) => {
    const cardRef = useRef(null);

    const state = useSelector(state => state.hhFrameworkCardset);
    const { statusTemplate } = state;
    const { provider } = useSelector(state => state.provider);
    const dispatch = useDispatch();

    const [card, setCard] = useState(null);

    const { provider_id } = provider;

    useEffect(() => {
        setCard(cardDetails);
    }, [cardDetails.status]);

    const getColorClass = (color, weight) => {
        switch (color) {
        case 'white': return 'bg-white text-black';
        case 'red': return 'bg-customRed text-black';
        case 'blue': return 'bg-blue-400 text-black';
        case 'yellow': return 'bg-customYellow text-black';
        case 'orange': return 'bg-orange-400 text-black';
        case 'green': return 'bg-customGreen text-black';
        case 'black': return 'bg-black text-black';
        case 'grey': return 'bg-customGray text-white';
        default: return 'bg-white text-black';
        }
    };

    const initCardset = (e) => {
        dispatch({
            type: ACTION.INITIATE_PATH,
            payload: {
                state,
                _travelPath: [`${cardsetId}`, `${cardDetails.id}`],
                _fromIndex: 0,
                _cardsetId: cardsetId,
                patientId
            }
        });
    };

    const statusHandler = () => {
        const statusObj = getUpdatedStatus(cardDetails.status, statusTemplate);
        const updatedStatus = statusObj.id;
        const transaction = {
            type: 'status',
            attr: {
                cardsetId,
                cardId: cardDetails.id,
                status: updatedStatus,
                statusId: statusObj.statusId,
                timeStamp: new Date().toISOString().split('T').join(' ')
            },
            providerId: provider_id
        };
        handleStatusUpdate(cardIndex, transaction.attr.cardId, updatedStatus);
        dispatch({
            type: HH_TRANS_ACTION.ADD_TRANSACTION,
            payload: transaction
        });
    };

    if (!card) {
        return null;
    }

    return (
        <>
            <div ref={cardRef} className={`w-full h-92 shadow-md border-2 border-[#E8E8E8] cursor-pointer
            ${statusTemplate?.[card.status]?.color ? getColorClass(statusTemplate?.[card.status].color) : null}
             font-bold rounded-md p-1 my-1  mx-2 flex flex-row items-center justify-between`}
            // onClick={() => statusHandler()}
            >
                <div className="w-[11%] cursor-pointer bg-white p-2 rounded border-2 border-[#E8E8E8]">
                    <FontAwesomeIcon icon={faCircleInfo} className="text-customGray" />
                </div>
                <div className={'w-3/5 text-md justify-self-center text-md p-1'}
                    onClick={() => statusHandler()}
                >
                    <p>{cardDetails.name}</p>
                </div>
                <div className={'flex flex-row-reverse w-[11%] bg-white cursor-pointer text-white p-2 rounded border-2 border-[#E8E8E8]'} onClick={(e) => initCardset(e)}>
                    <FontAwesomeIcon icon={faArrowRightLong} className='text-customGray' />
                </div>
            </div>
        </>
    );
};

export default Card;
