import React from 'react';
import * as d3 from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faNotesMedical, faVial, faUserDoctor, faBookOpen, faPrescription, faHouseMedical } from '@fortawesome/free-solid-svg-icons';

import Modal from '../../components/ui/Modal';

const ModalContainer = (props) => {
    // utils & constants
    const parseMonth = d3.timeFormat("%b '%y");
    const parseWeek = d3.timeFormat('%m/%d');
    const parseLocalDateString = d3.timeFormat("%d %b '%y");

    const parseDateByType = (date, type) => {
        date = new Date(date);
        switch (type) {
        case 'pastMonths':
        case 'futureMonths': return parseMonth(date);
        case 'pastWeeks':
        case 'futureWeeks': return 'Week ' + parseWeek(date);
        case 'currentWeek':
            return parseLocalDateString(date);
        default:
            return d3.timeFormat(date)('%x');
        }
    };

    return (
        <Modal {...props} >
            <div className='overflow-scroll w-full p-8 space-y-2'>
                <div className='h-12 text-left text-lg text-white bg-primary flex px-4'>
                    <p className='my-auto'>{parseDateByType(props.data.xDate, props.data.xAxisType)} &nbsp; - &nbsp; {props.data.yAxisType}</p>
                </div>
                <div className='space-y-2'>
                    {props.data.data.map(attr => generateContent(props.data.type, attr))}
                </div>
            </div>
        </Modal>
    );
};

const generateContent = (type, dataArr) => {
    const data = dataArr;

    const parseDate = (date) => {
        return d3.timeFormat("%d %b '%y")(new Date(date));
    };

    switch (type) {
    case 'Visit':
        return <div className='border-2 border-gray-400 p-2 space-y-4'>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faUserDoctor} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Dr.Name</p>
                </div>
                <p>:</p>
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faCalendarDays} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Date</p>
                </div>
                <p>:</p>
                <p>{parseDate(data.visitDate)}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faNotesMedical} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Remarks</p>
                </div>
                <p>:</p>
                <p className='italic'>`&quot;`{data.remarks}`&quot;`</p>
            </div>
        </div>;
    case 'Appointment':
        return <div className='border-2 border-gray-400 p-2 space-y-4'>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faUserDoctor} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Dr.Name</p>
                </div>
                <p>:</p>
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faCalendarDays} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Date</p>
                </div>
                <p>:</p>
                <p>{parseDate(data.appointmentDate)}</p>
            </div>
        </div>;
    case 'Lab':
        return <div className='border-2 border-gray-400 p-2 space-y-4'>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faUserDoctor} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Dr.Name</p>
                </div>
                <p>:</p>
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faCalendarDays} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Test Date</p>
                </div>
                <p>:</p>
                <p>{parseDate(data.labTestDate)}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faVial} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Test</p>
                </div>
                <p>:</p>
                <p>{data.testedFor}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faNotesMedical} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Report</p>
                </div>
                <p>:</p>
                <p>{data.testSummary}</p>
            </div>
        </div>;
    case 'Blood Glucose':
        return <div className='border-2 border-gray-400 p-2 space-y-4'>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faUserDoctor} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Dr.Name</p>
                </div>
                <p>:</p>
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faCalendarDays} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Test Duration</p>
                </div>
                <p>:</p>
                <p>{parseDate(data.startDate)}  -  {parseDate(data.endDate)}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faBookOpen} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Reading mode</p>
                </div>
                <p>:</p>
                <p>{data.readingMode}</p>
            </div>
        </div>;
    case 'Prescription Refill':
        return <div className='border-2 border-gray-400 p-2 space-y-4'>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faUserDoctor} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Dr.Name</p>
                </div>
                <p>:</p>
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faCalendarDays} className={`${data.refill === 'True' ? 'text-gray-600' : 'text-customRed'}`} />
                    </div>
                    <p className='text-primary'>Refill date</p>
                </div>
                <p>:</p>
                <p>{parseDate(data.refillDate)}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faHouseMedical} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Pharmacy</p>
                </div>
                <p>:</p>
                <p>{data.boughtAt}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center w-full'>
                <div className='flex flex-row space-x-2 items-center w-3/12'>
                    <div className='bg-gray-200 w-8 h-8 flex justify-center items-center rounded-full'>
                        <FontAwesomeIcon icon={faPrescription} className="text-gray-600" />
                    </div>
                    <p className='text-primary'>Prescription</p>
                </div>
                <p>:</p>
                <p className='list-decimal'>{data.data.map((med, i) => <li key={i}>Name: {med.medicine} | Type: {med.drugType} | Quantity: {med.qty} | Dosage: {med.dosage}</li>)}</p>
            </div>
        </div>;
    default: return '';
    }
};

export default ModalContainer;
