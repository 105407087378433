import { ACTION } from './action';

const initialState = {
    provider: {}
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION.ADD_PROVIDER_STATE:
        return {
            ...state,
            provider: action.provider
        };
    case ACTION.UPDATE_PROVIDER:
        return {
            ...state,
            provider: action.provider
        };
    case ACTION.DELETE_PROVIDER:
        return {
            ...state,
            provider: {}
        };
    default:
        return state;
    }
};
