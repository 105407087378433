import React from 'react';

import { convertToPercentage } from '../../utils';

const MultiSlider = (props) => {
    const { range = [{ start: 0, end: 0, color: 'green' }], marks = [] } = props;

    const min = props.min || 0;
    const max = props.max || 100;

    const getBgColor = (color, weight) => {
        switch (color) {
        case 'white': return 'bg-white';
        case 'red': return 'bg-red-400';
        case 'blue': return 'bg-blue-400';
        case 'yellow': return 'bg-yellow-400';
        case 'orange': return 'bg-orange-400';
        case 'green': return 'bg-green-400';
        case 'black': return 'bg-black';
        case 'gray': return 'bg-gray-400';
        default: return 'bg-white';
        }
    };

    const getPercentage = (value) => {
        return convertToPercentage(min, max, value);
    };

    return (
        <div className="w-full h-2 bg-white text-black dark:bg-customGray relative rounded-lg flex z-0">
            {range.map((bars, i) => {
                return <div key={i} className={`text-xs font-bold text-white text-center p-1 ${getBgColor(bars.color)}`} style={{ left: `${getPercentage(bars.fromValue)}%`, width: `${getPercentage(bars.toValue) - getPercentage(bars.fromValue)}%` }}></div>;
            }
            )}
            {marks.map((mark, i) => {
                return <div key={i} className={'self-center absolute h-6 p-px flex bg-gray-500 rounded'} style={{ left: `${getPercentage(mark.value)}%` }} />;
            })}
        </div>
    );
};

export default MultiSlider;
