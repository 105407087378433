import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { ACTION } from '../../store/hhFrameworkTransaction/action';
import FormikControl from '../../components/formik/FormikControl';

const CardNotes = (props) => {
    const initialValues = {
    };

    const resetInitValues = {
    };

    const dispatch = useDispatch();

    const [template, setTemplate] = useState(null);

    useEffect(() => {
        setTemplate(props.template);
    }, [props.statePath]);

    const saveNotes = (data, actions) => {
        const transaction = {
            type: 'formAttr',
            attr: {
                cardsetId: props.cardsetId,
                cardId: props.cardsetId,
                formAttr: data,
                timeStamp: new Date().toISOString().split('T').join(' ')
            }
            // providerId: provider_id
        };
        dispatch({
            type: ACTION.ADD_TRANSACTION,
            payload: transaction
        });
        dispatch({
            type: ACTION.HARD_PUSH_TRANSACTIONS
        });
        actions.resetForm(resetInitValues);
    };

    return (
        <Formik
            key={props.cardsetId}
            initialValues={initialValues}
            enableReinitialize
            // validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                saveNotes(values, actions);
                actions.setSubmitting(false);
            }}
        >
            <Form className='space-y-4'>
                {template
                    ? template.map((row, i) => (
                        <div key={i}>
                            <div className={'flex flex-row justify-start justify-between'}>
                                {row.line.map(field => {
                                    initialValues[field.name] = '';
                                    resetInitValues[field.name] = '';
                                    // validationSchema
                                    return <FormikControl key={field.name} control={field.type} label={field.name} name={field.name} />;
                                })}
                            </div>
                        </div>
                    ))
                    : null
                }
                <div className='flex flex-row w-100 justify-evenly'>
                    <div className=''>
                        <button type='submit' className="bg-primary text-white font-bold py-2 px-4 rounded">Save</button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default CardNotes;
