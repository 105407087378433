import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '../../components/ui/Tooltip';
import { formatDate } from '../../utils';

const CardNotesHistoryTable = ({ notesHistory }) => {
    const { statusTemplate } = useSelector(state => state.hhFrameworkCardset);

    const getBgColor = (color, weight) => {
        switch (color) {
        case 'white': return 'bg-white';
        case 'red': return 'bg-red-500';
        case 'blue': return 'bg-blue-500';
        case 'yellow': return 'bg-yellow-500';
        case 'orange': return 'bg-orange-500';
        case 'green': return 'bg-green-500';
        case 'black': return 'bg-black';
        case 'gray': return 'bg-gray-500';
        default: return 'bg-white';
        }
    };

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="">
                        <div className='w-full border-b-2 border-gray'>
                            <p className='text-lg font-bold text-customGray flex justify-start'>History</p>
                        </div><br />
                        {notesHistory.length
                            ? <table name="table-fixed">
                                <thead className="border-b bg-primary">
                                    <tr>
                                        <th scope="col" className="text-md font-bold text-white px-2 py-3 text-start">
                                        </th>
                                        <th scope="col" className="text-md font-bold text-white px-6 py-3 text-start">
                                            Date
                                        </th>
                                        <th scope="col" className="text-md font-bold text-white px-6 py-3 text-start">
                                            Name
                                        </th>
                                        <th scope="col" className="text-md font-bold text-white px-6 py-3 text-start">
                                            Role
                                        </th>
                                        <th scope="col" className="text-md font-bold text-white px-6 py-3 text-start">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        notesHistory.map((notes, i) => {
                                            return (
                                                <tr className="border-b text-start" key={i}>
                                                    <td className="px-3 py-4 text-sm font-medium text-gray-900">
                                                        <Tooltip tooltipText={statusTemplate[notes.status].name} orientation="right">
                                                            <div data-tooltip-target="tooltip-default" className={`h-4 w-4 rounded-full ${getBgColor(statusTemplate[notes.status].color)}`} />
                                                        </Tooltip>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {formatDate(notes.date)}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {notes.user}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {notes.role}
                                                    </td>
                                                    <td className="text-sm text-gray-900 max-w-24 text-left font-light px-6 py-4 overflow-hidden truncate">
                                                        <p>{getSavedData(notes.data)}</p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            : <div className='text-center'>No records available</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const getSavedData = (data) => {
    const stringData = [];
    if (data && Object.keys(data).length !== 0) {
        if (data.status) { delete data.status; }
        const keys = Object.keys(data);
        for (const detail in data) {
            if (detail === keys[keys.length - 1]) {
                stringData.push(
                    <>
                        <span className='text-primary'>{detail} : </span>
                        <span>{data[detail]}</span>
                    </>
                );
            } else {
                stringData.push(
                    <>
                        <span className='text-primary'>{detail} : </span>
                        <span>{data[detail]} | </span>
                    </>
                );
            }
        }
    } else {
        stringData.push(
            <>
                <span className='text-blaclk font-bold'>-</span>
            </>
        );
    }
    return stringData;
};

export default CardNotesHistoryTable;
