import React from 'react';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormikControl from '../../components/formik/FormikControl';

const Behavior = ({ data }) => {
    const initialValues = {
        test: false
    };
    return (
        <div className="w-full">
            <div className="header w-full rounded bg-primary text-white flex flex-row items-center space-x-2">
                {data.icon ? <FontAwesomeIcon icon={['fas', data.icon]} className="text-white px-2" /> : null}
                <p className="py-1">{data.title}</p>
            </div><br />
            <div className="body space-y-4">
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={values => {
                        // saveNotes(values)

                    }}
                >
                    <Form >
                        <div className="space-y-4">
                            {data.attribute.map((attr, i) => {
                                initialValues[attr.behaviorType] = attr.isActive === 'yes';
                                return <div className="pt-2" key={i}>
                                    <FormikControl control="checkbox" label={attr.behaviorType} name={attr.behaviorType} disabled={true} />
                                </div>;
                            })}

                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default Behavior;
