import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faCalendarDays, faNotesMedical, faVial, faPrescription } from '@fortawesome/free-solid-svg-icons';

const Tooltip = ({ data }) => {
    const [divHeight, setDivHeight] = useState(0);
    const { type, left, top } = data;
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) { setDivHeight(divRef.current.clientHeight); }
    }, [data]);

    return (
        <div className='calenderTooltipParentContainer' ref={divRef} style={{ left, top: top - divHeight }}>
            <div className='tooltipContainer text-white'>
                {generateTooltip(type, data.data)}
            </div>
        </div>
    );
};

const generateTooltip = (type, dataArr) => {
    const data = dataArr[0];

    const parseDate = (date) => {
        return d3.timeFormat("%d %b '%y")(new Date(date));
    };

    switch (type) {
    case 'Visit':
        return <>
            <div>
                <p className='tooltipStatus'>{type}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon icon={faStethoscope} className="text-white" />
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon icon={faCalendarDays} className="text-white" />
                <p>{parseDate(data.visitDate)}</p>
            </div>
            <div className='flex flex-row italic space-x-2 items-center'>
                <FontAwesomeIcon icon={faNotesMedical} className="text-white" />
                <p>`&quot;`{data.remarks}`&quot;`</p>
            </div>
        </>;
    case 'Appointment':
        return <>
            <div>
                <p className='tooltipStatus'>{type}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon icon={faStethoscope} className="text-white" />
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center text-sm'>
                <FontAwesomeIcon icon={faCalendarDays} className="text-white" />
                <p>{parseDate(data.appointmentDate)}</p>
            </div>
        </>;
    case 'Lab':
        return <>
            <div>
                <p className='tooltipStatus'>{type}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon icon={faStethoscope} className="text-white" />
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center text-sm'>
                <FontAwesomeIcon icon={faCalendarDays} className="text-white" />
                <p>{parseDate(data.labTestDate)}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center text-sm'>
                <FontAwesomeIcon icon={faVial} className="text-white" />
                <p>{data.testedFor}</p>
            </div>
            <div className='flex flex-row italic space-x-2 items-center'>
                <FontAwesomeIcon icon={faNotesMedical} className="text-white" />
                <p>`&quot;`{data.testSummary}`&quot;`</p>
            </div>
        </>;
    case 'Blood Glucose':
        return <>
            <div>
                <p className='tooltipStatus'>{type}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon icon={faStethoscope} className="text-white" />
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center text-sm'>
                <FontAwesomeIcon icon={faCalendarDays} className="text-white" />
                <p>{parseDate(data.startDate)}  -  {parseDate(data.endDate)}</p>
            </div>
            <div className='flex flex-row italic space-x-2 items-center'>
                <FontAwesomeIcon icon={faNotesMedical} className="text-white" />
                <p>`&quot;`{data.readingMode}`&quot;`</p>
            </div>
        </>;
    case 'Prescription Refill':
        return <>
            <div>
                <p className='tooltipStatus'>{type}</p>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
                <FontAwesomeIcon icon={faStethoscope} className="text-white" />
                <p>Dr. <span>{data.provider_name}</span><span className='text-sm px-2'>({data.provider_type})</span></p>
            </div>
            <div className='flex flex-row space-x-2 items-center text-sm'>
                <FontAwesomeIcon icon={faCalendarDays} className={`${data.refill === 'True' ? 'text-white' : 'text-customRed'}`} />
                <p>{parseDate(data.refillDate)}</p>
            </div>
            <div className='flex flex-row italic space-x-2 items-center'>
                <FontAwesomeIcon icon={faPrescription} className="text-white" />
                <p>{data.data.map((med, i) => <span key={i}>
                    {med.medicine}
                </span>)}</p>
            </div>
        </>;
    default: return '';
    }
};

export default Tooltip;
