import React from 'react';
import Select from 'react-select';

export const CustomSelect = ({
    className,
    placeholder,
    field,
    form,
    options,
    isMulti = false
}) => {
    const onChange = (option) => {
        form.setFieldValue(
            field.name,
            isMulti
                ? option.map((item) => item.value)
                : option.value
        );
    };

    const getValue = () => {
        if (options) {
            if (isMulti) {
                return options.filter(option => field.value.indexOf(option.value) >= 0);
            } else {
                // Check if current field value is found in options array
                const selectedOption = options.find(option => option.value === field.value);
                if (selectedOption) {
                    return selectedOption;
                } else {
                    // If current value is not found, return an empty string to reset the field
                    return '';
                }
            }
        } else {
            return isMulti ? [] : '';
        }
    };

    return (
        <Select
            className={className}
            name={field.name}
            value={getValue()}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            getOptionLabel={e => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                </div>
            )}
        />
    );
};

export default CustomSelect;
