import { all, fork } from 'redux-saga/effects';

import { watchHHFramework } from './hhFramework/saga';
import { watchAddTransaction } from './hhFrameworkTransaction/saga';
import { watchPatient } from './patient/saga';
import { watchProvider } from './provider/saga';

function* rootSaga () {
    yield all([
        fork(watchHHFramework),
        fork(watchAddTransaction),
        fork(watchPatient),
        fork(watchProvider)
    ]);
}

export default rootSaga;
