import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Event = ({ data }) => {
    const getBgColor = (color, weight) => {
        switch (color) {
        case 'white': return 'bg-white';
        case 'red': return 'bg-red-500';
        case 'blue': return 'bg-blue-500';
        case 'yellow': return 'bg-yellow-500';
        case 'orange': return 'bg-orange-500';
        case 'green': return 'bg-green-500';
        case 'black': return 'bg-black';
        case 'gray': return 'bg-gray-500';
        default: return 'bg-white';
        }
    };

    return (
        <div className="w-full">
            <div className="header w-full rounded bg-primary text-white flex flex-row items-center space-x-2">
                {data.icon ? <FontAwesomeIcon icon={['fas', data.icon]} className="text-white px-2" /> : null}
                <p className="py-1">{data.title}</p>
            </div><br />
            <div className="body space-y-4">
                {
                    data.attribute.map((attr, i) => (
                        <div key={i} className={`flex flex-row space-x-2 pt-2 ${attr.earliestOccurence ? 'text-black' : 'text-gray-500'}`}>
                            <div className=" w-1/2 flex flex-row justify-between space-x-2 items-stretch">
                                <p className="text-sm w-3/4 truncate" title={attr.eventType}>{attr.eventType}</p>
                                <div data-tooltip-target="tooltip-default" className={`h-2 w-2 self-center rounded-full ${attr.count > 1 ? getBgColor('red') : getBgColor('gray')}`} />
                                {/* <StarRating /> */}
                            </div>
                            <p className='w-1/2'>{attr.earliestOccurence}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Event;
