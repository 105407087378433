import { takeEvery, put } from 'redux-saga/effects';
import { ACTION } from './action';

function* addProvider ({ payload }) {
    yield put({
        type: ACTION.ADD_PROVIDER_STATE, provider: payload
    });
}

export function* watchProvider () {
    yield takeEvery(ACTION.ADD_PROVIDER, addProvider);
}
