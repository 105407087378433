import React, { useState, useMemo, useEffect } from 'react';

const DotRuler = ({ data, key, reset, updateRulerVal }) => {
    const [currentValue, setCurrentValue] = useState(data.currentValue);

    useMemo(() => {
        setCurrentValue(data.currentValue);
    }, [data.currentValue]);

    useEffect(() => {
        setCurrentValue(data.currentValue);
    }, [reset]);

    const updateCurrentValue = (value) => {
        setCurrentValue(value);
        updateRulerVal(value);
    };

    return (
        <div id={key}>
            <PopulateDot data={data} currentValue={currentValue} updateCurrentValue={updateCurrentValue} />
        </div>
    );
};

const PopulateDot = ({ data, currentValue, updateCurrentValue }) => {
    const dots = [];

    for (let i = data.minScale; i <= data.maxScale; i++) {
        dots.push(
            // eslint-disable-next-line quotes
            <div
                className={`w-8 h-8 ${i <= currentValue && 'bg-green-400'} border-2 border-border rounded-full flex justify-center items-center cursor-pointer`}
                onClick={() => updateCurrentValue(i)}
                key={data.questionId + '_' + i}
            >
                {i}
            </div>
        );
    }
    return (
        <div className='space-y-2 p-2'>
            <p>{data.question}</p>
            <div className='flex flex-row space-x-1 justify-center'>
                {dots}
            </div>
        </div>
    );
};

export default DotRuler;
