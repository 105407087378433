import { timeFormat } from 'd3-time-format';

export const convertToPercentage = (min, max, value) => (value - min) / (max - min) * 100;

export const formatDate = (date) => {
    const locDate = new Date(date);
    return timeFormat("%d %b '%y")(locDate);
};

export const formatCustomDate = (date, type) => {
    const locDate = new Date(date);
    switch (type) {
    case 'yyyy-mm-dd':
        return timeFormat('%Y-%m-%d')(locDate);
    case 'mm-dd-yyyy':
        return timeFormat('%m-%d-%Y')(locDate);
    default:
        return timeFormat("%d %b '%y")(locDate);
    }
};

export const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
