import { call, put, takeLatest, select } from 'redux-saga/effects';
import { axiosPublic } from '../../api';
import { ACTION } from './action';
import { ACTION as HHFTRANS } from '../hhFrameworkTransaction/action';
import { hardPushtransactions } from '../hhFrameworkTransaction/saga';

function* initMethod ({ payload }) {
    yield call(hardPushtransactions);
    if (payload._cardsetId !== payload.state.cardsetId) {
        const cardsetData = yield call(fetchCardsetTree, payload.patientId, payload._cardsetId);
        const activeArray = [];
        for (const level of payload._travelPath) {
            activeArray.push(yield call(generateCardset, cardsetData, level));
        }
        const newState = {
            tree: cardsetData,
            activePath: payload._travelPath,
            activeArray,
            pathDepth: activeArray.length,
            cardsetId: payload.state.cardsetId
        };
        yield put({ type: ACTION.INITIATE_STATE, payload: { state: newState } });
    }
}

function* expandPath ({ payload }) {
    if (payload.state.tree[payload.cardId]) {
        payload.state.activeArray = payload.state.activeArray.slice(0, payload._fromIndex);
        payload.state.activePath = payload.state.activePath.slice(0, payload._fromIndex);
        const activeArray = [...payload.state.activeArray, yield call(generateCardset, payload.state.tree, payload.cardId)];
        const activePath = [...payload.state.activePath, payload.cardId];
        const pathDepth = payload.state.pathDepth + 1;
        const updatedState = {
            activePath,
            activeArray,
            pathDepth,
            cardsetId: payload.state.cardsetId
        };
        yield put({ type: ACTION.UPDATE_STATE, payload: { state: updatedState } });
    }
}

function* trimPath ({ payload }) {
    const activeArray = payload.state.activeArray.slice(0, payload._fromIndex);
    const activePath = payload.state.activePath.slice(0, payload._fromIndex);
    const pathDepth = activeArray.length;
    const updatedState = {
        activePath,
        activeArray,
        pathDepth,
        cardsetId: payload.state.cardsetId
    };
    if (!activePath.length) {
        yield call(hardPushtransactions);
        yield call(syncMainboard);
    }
    yield put({ type: ACTION.UPDATE_STATE, payload: { state: updatedState } });
}

function* generateCardset (tree, id) {
    const _tree = JSON.parse(JSON.stringify(tree));
    const parent = _tree[id];
    const newAttr = parent.attributes.map(attr => _tree[attr]);
    parent.attributes = newAttr;
    return parent;
}

function* fetchCardsetTree (patientId, cardsetId) {
    const _cardsetId = cardsetId.split('-')[0];
    const resp = yield call(axiosPublic.get, `/hb-health-framework/child-cards/patients/${patientId}?cardset_id=${_cardsetId}`);
    return resp.data;
}

function* updateStatus ({ payload }) {
    const cardsetId = payload.cardsetId;
    const cardId = payload.cardId;
    const { statusTemplate } = yield select(state => state.hhFrameworkCardset);
    const newStatus = yield call(getUpdatedStatus, payload.status, statusTemplate);
    const _tree = JSON.parse(JSON.stringify(payload.state.tree));
    _tree[cardId].status = newStatus.id;
    const updatedState = {
        ...payload.state,
        tree: _tree
    };
    const transaction = {
        type: 'status',
        attr: {
            cardId,
            cardsetId,
            status: newStatus.id,
            statusId: newStatus.statusId,
            timeStamp: new Date().toISOString().split('T').join(' ')
        }
    };
    // yield put({ type: ACTION.ADD_PROVIDER_ID }, { payload: payload.providerId })
    yield put({ type: ACTION.UPDATE_STATE, payload: { state: updatedState } });
    yield put({ type: HHFTRANS.ADD_TRANSACTION, payload: transaction });
}

export const getUpdatedStatus = (currentStatus, statusTemplate) => {
    const statuses = Object.keys(statusTemplate);
    statuses.sort((a, b) => statusTemplate[a].rank - statusTemplate[b].rank);
    const currentIndex = statuses.indexOf(currentStatus);
    const nextIndex = (currentIndex + 1) % statuses.length;
    return statusTemplate[statuses[nextIndex]];
};

function* syncMainboard () {
    const { provider } = yield select(state => state.provider);
    const { patient } = yield select(state => state.patient);
    const { data } = yield call(axiosPublic.get, `/hb-health-framework?patient_id=${patient.patient_id}&provider_id=${provider.provider_id}`);
    yield put({ type: ACTION.INITIATE_MAIN, payload: data });
}

function* syncStatusTemplate () {
    const { data } = yield call(axiosPublic.get, '/hb-health-framework/templates/card-color-template');
    for (const stat in data) {
        data[stat].id = stat;
    }
    yield put({ type: ACTION.INITIATE_STATUS_TEMPLATE, payload: data });
}

function* updateIntervention ({ payload }) {
    const hhFrameworkCardset = yield select(state => state.hhFrameworkCardset);
    const { patient } = yield select(state => state.patient);
    const { tree, statusTemplate } = hhFrameworkCardset;
    const selectedResource = [];
    for (const resourcesId of payload.resourceIdSent) {
        const resObj = tree[payload.cardId].availableResourceDetails.find(res => res.resourceId === resourcesId);
        resObj.id = resObj.resourceId;
        selectedResource.push(resObj);
    }
    const rulerResponse = [];
    for (const rulerRes of payload.rulerResponse) {
        const res = {};
        res.id = rulerRes.questionId;
        res.rulerValue = rulerRes.providerResponse;
        rulerResponse.push(res);
    }
    tree[payload.cardId].interventionHistory.resourceSelected = selectedResource;
    tree[payload.cardId].interventionHistory.goalSelected = payload.goalSet;
    tree[payload.cardId].interventionHistory.rulerScore = rulerResponse;
    let cardStatus = tree[payload.cardId].status;
    payload.status = tree[payload.cardId].status;
    payload.isStatusUpdated = false;
    if (tree[payload.cardId].status === 'to_be_done') {
        const updatedStatus = yield call(getUpdatedStatus, 'to_be_done', statusTemplate);
        cardStatus = updatedStatus.id;
        tree[payload.cardId].status = updatedStatus.id;
        tree[payload.cardId].statusId = updatedStatus.statusId;
        payload.isStatusUpdated = false;
        payload.status = cardStatus;
    }
    yield call(axiosPublic.post, `/hb-health-framework/interventions/${patient.patient_id}`, payload);
    const updatedState = {
        ...hhFrameworkCardset,
        tree
    };
    yield put({ type: ACTION.UPDATE_STATE, payload: { state: updatedState } });
    yield;
}

export function* watchHHFramework () {
    yield takeLatest(ACTION.INITIATE_PATH, initMethod);
    yield takeLatest(ACTION.EXPAND_PATH, expandPath);
    yield takeLatest(ACTION.TRIM_PATH, trimPath);
    yield takeLatest(ACTION.UPDATE_STATUS, updateStatus);
    yield takeLatest(ACTION.SYNC_MAINBOARD, syncMainboard);
    yield takeLatest(ACTION.SYNC_STATUS_TEMPLATE, syncStatusTemplate);
    yield takeLatest(ACTION.UPDATE_INTERVENTION, updateIntervention);
}
