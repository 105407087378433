import { takeEvery, call, put, select, takeLatest } from 'redux-saga/effects';
import { ACTION } from './action';
import { ACTION as HHBACTION } from '../hhFramework/action';
import { axiosPublic } from '../../api';
// import { sendTransactionsToBackend } from './api';
import { delay } from '../../utils';
// import showToast from '../../components/ui/Toast';

const N = 15;
const N_SECONDS = N * 1000;

let isTransActive = false;

function* sendTransactionsSaga () {
    if (!isTransActive) {
        isTransActive = true;
        let transIdleSec = 0;
        while (true) {
            if (transIdleSec >= N * 3) {
                break;
            }
            yield call(delay, N_SECONDS);
            const state = yield select(state => state.hhFrameworkTrans);
            const { transactions } = state;
            if (!(Object.keys(transactions.status).length || Object.keys(transactions.formAttr).length)) {
                transIdleSec += N;
            } else {
                transIdleSec = 0;
                yield call(transmitTransaction, transactions);
            }
        }
    }
}

export function* hardPushtransactions () {
    const state = yield select(state => state.hhFrameworkTrans);
    const { transactions } = state;
    if ((Object.keys(transactions.status).length || Object.keys(transactions.formAttr).length)) {
        yield call(transmitTransaction, transactions);
        yield put({ type: HHBACTION.SYNC_MAINBOARD });
    }
}

function* transmitTransaction (transactions) {
    const { provider } = yield select(state => state.provider);
    const trandsBody = {
        providerId: provider.provider_id,
        statusData: transactions.status,
        formsData: transactions.formAttr
    };
    yield call(sendTransaction, trandsBody);
    yield put({ type: ACTION.RESET_TRANSACTIONS });
    return Promise.resolve('transmit transaction completed');
}

function* sendTransaction (data) {
    try {
        const { patient } = yield select(state => state.patient);
        yield call(axiosPublic.post, `/hb-health-framework/patient-progress/${patient.patient_id}`, data);
    } catch (e) {
        console.error('error occured in herringbone framework transaction: ', e);
    }
}

function* sendIntervention ({ payload }) {
    try {
        yield put({ type: HHBACTION.UPDATE_INTERVENTION_TRANS_STATUS, payload: { interventionStatus: true } });
        yield;
        const { provider } = yield select(state => state.provider);
        // const { patient } = yield select(state => state.patient);
        payload.providerId = provider.provider_id;
        // yield call(axiosPublic.post, `/hb-health-framework/interventions/${patient.patient_id}`, payload);
        yield put({
            type: HHBACTION.UPDATE_INTERVENTION,
            payload
        });
        yield;
        yield put({ type: HHBACTION.UPDATE_INTERVENTION_TRANS_STATUS, payload: { interventionStatus: false } });
        // showToast('Interventions sent successfully', 5000);
    } catch (e) {
        yield put({ type: HHBACTION.UPDATE_INTERVENTION_TRANS_STATUS, payload: { interventionStatus: false } });
        console.error('error occured in herringbone framework transaction: ', e);
    }
}

function* addTransaction ({ payload }) {
    const state = yield select(state => state.hhFrameworkTrans);
    const { transactions } = state;
    const actionType = transactions[payload.type];
    actionType[payload.attr.cardId] = payload.attr;
    yield put({ type: ACTION.ADD_TRANSACTION_STATE, transactions });
    yield call(sendTransactionsSaga);
}

export function* watchAddTransaction () {
    yield takeEvery(ACTION.ADD_TRANSACTION, addTransaction);
    yield takeEvery(ACTION.HARD_PUSH_TRANSACTIONS, hardPushtransactions);
    yield takeLatest(ACTION.SEND_INTERVENTION, sendIntervention);
}
