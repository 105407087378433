import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ACTION } from '../../store/patient/action';
import PatientModule from '../../modules/Patient';

const Patient = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!state || state.patient_id !== id) {
            navigate('/provider/patient-list');
        } else {
            dispatch({
                type: ACTION.ADD_PATIENT,
                payload: state
            });
        }
    }, [state, id]);

    return (
        <PatientModule />
    );
};

export default Patient;
