import React from 'react';
import { Route } from 'react-router-dom';

import Patient from '../pages/Patient';
import Provider from '../pages/Provider';

export const ProviderRoutes = (
    <>
        {/* TODO: fix routes */}
        <Route path="/provider/patient-list" element={<Provider />} />
        <Route path='/provider/patient/:id' element={<Patient />} />
    </>
);
