import React from 'react';

const PatientDescription = ({ descTemplate }) => {
    return (
        <div>
            <ul className="px-4 list-disc">
                {
                    descTemplate.Data.map((desc, i) => {
                        return <li key={i}>
                            <p>{desc}</p>
                        </li>;
                    })
                }
            </ul>
        </div>
    );
};

export default PatientDescription;
