import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import './App.css';
import PublicRoutes from './routes/PublicRoutes';

function App () {
    const iconList = Object.keys(Icons)
        .filter((key) => key !== 'fas' && key !== 'prefix')
        .map((icon) => Icons[icon]);

    library.add(...iconList);

    return (
        <PublicRoutes />
    );
}

export default App;
