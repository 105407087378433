/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { ACTION } from '../../store/hhFramework/action';

const Card = ({ cardsetId, cardDetails, id, cardsetIndex, handleStatusUpdate, cardIndex }) => {
    const cardRef = useRef(null);
    const dispatch = useDispatch();
    const state = useSelector(state => state.hhFrameworkCardset);
    const { tree } = state;
    const { statusTemplate } = useSelector(state => state.hhFrameworkCardset);
    const { provider } = useSelector(state => state.provider);

    const { provider_id } = provider;

    const [card, setCard] = useState(null);
    const [isCardOpen, setIsCardOpen] = useState(false);

    useEffect(() => {
        setCard(tree[cardDetails.id]);
    }, [tree[cardDetails.id]]);

    useEffect(() => {
        if (state.activePath.includes(id)) {
            setIsCardOpen(true);
        } else {
            setIsCardOpen(false);
        }
    }, [state]);

    const handleNext = () => {
        if (isCardOpen) {
            dispatch({
                type: ACTION.TRIM_PATH,
                payload: {
                    state,
                    _fromIndex: cardsetIndex + 1
                }
            });
        } else {
            dispatch({
                type: ACTION.EXPAND_PATH,
                payload: {
                    state,
                    cardId: id,
                    _fromIndex: cardsetIndex + 1,
                    _cardsetId: cardsetId
                }
            });
        }
    };

    const statusHandler = () => {
        const payload = {
            state,
            cardsetId,
            cardId: cardDetails.id,
            status: cardDetails.status,
            statusId: cardDetails.statusId,
            timeStamp: new Date().toISOString().split('T').join(' '),
            providerId: provider_id
        };
        handleStatusUpdate(cardIndex, payload.cardId, payload.status);
        dispatch({
            type: ACTION.UPDATE_STATUS,
            payload
        });
    };

    const getColorClass = (color, weight) => {
        switch (color) {
        case 'white': return 'bg-white text-black';
        case 'red': return 'bg-customRed text-black';
        case 'blue': return 'bg-blue-400 text-black';
        case 'yellow': return 'bg-customYellow text-black';
        case 'orange': return 'bg-orange-400 text-black';
        case 'green': return 'bg-customGreen text-black';
        case 'black': return 'bg-black text-black';
        case 'grey': return 'bg-customGray text-white';
        default: return 'bg-white text-black';
        }
    };
    if (!card) {
        return null;
    }

    return (
        <>
            <div ref={cardRef} className={`w-full shadow-md border-2 border-[#E8E8E8] cursor-pointer
        ${statusTemplate?.[card.status]?.color ? getColorClass(statusTemplate?.[card.status].color) : null}
         font-bold rounded-md p-1 my-1  mx-2 flex flex-row items-center justify-between`}
            // onClick={() => statusHandler()}
            >
                <div className="w-[11%] cursor-pointer bg-white p-2 rounded border-2 border-[#E8E8E8]" title={card.cardDescription}>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-customGray" />
                </div>
                <div className={'w-4/5 justify-self-center text-md p-2'}
                    onClick={() => statusHandler()}
                >
                    <p>{cardDetails.name}</p>
                </div>
                <div className={`w-[11%] 
                    ${isCardOpen ? 'bg-primary' : 'bg-white'} 
                    cursor-pointer text-white p-2 rounded border-2 border-[#E8E8E8]`}
                onClick={(e) => handleNext(e)}>
                    <FontAwesomeIcon icon={faArrowRightLong} className={`${isCardOpen ? 'text-white' : 'text-customGray'}`} />
                </div>
            </div>
        </>
    );
};

export default Card;
