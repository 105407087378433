import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import { ProviderRoutes } from './ProviderRoutes';
import { ACTION } from '../store/provider/action';

const PublicRoutes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: ACTION.ADD_PROVIDER,
            payload: {
                provider_dob: '1974-04-12',
                provider_id: '1f9346d8-5eb0-4c8a-96df-f5d957104b71',
                provider_name: 'John Wright',
                provider_npi: '12345',
                provider_role: 'Dietician'
            }
        });
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/provider/patient-list" replace />} />
                <Route path="/provider">
                    {ProviderRoutes}
                </Route>
            </Routes>
        </Router>
    );
};

export default PublicRoutes;
