import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { AnimatePresence, motion } from 'framer-motion';

import { axiosPublic } from '../../api';
import Tooltip from './Tooltip';
import ModalContainer from './ModalContainer';

const CalenderChart = () => {
    const d3Chart = useRef();
    const wrapperRef = useRef();
    const { patient } = useSelector(state => state.patient);

    const [showTooltip, setShowTooltip] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [tooltipData, setTooltipData] = useState({
        type: '',
        data: [],
        left: null,
        top: null
    });

    const [modalData, setModalData] = useState({
        type: '',
        data: [],
        xAxisType: '',
        xDate: '',
        yAxisType: ''
    });

    const animateVariants = {
        open: { opacity: 1 },
        closed: { opacity: 0 }
    };

    useEffect(() => {
        if (patient.patient_id) {
            axiosPublic.get(`/timeline/${patient.patient_id}`)
                .then(res => {
                    renderCanvas(res.data);
                });
        }
    }, [patient]);

    const renderCanvas = (data) => {
        const height = 255;
        const width = 1700;
        const cellWidth = 40;
        const yAxisDomain = data.columns;

        // utils & constants
        const parseMonth = d3.timeFormat("%b '%y");
        const parseWeek = d3.timeFormat('%m/%d');
        const parseDay = d3.timeFormat('%a');
        const parseLocalDateString = d3.timeFormat('%d/%m/%Y');

        const parseDate = (date, type) => {
            date = new Date(date);
            switch (type) {
            case 'pastMonths':
            case 'futureMonths': return parseMonth(date);
            case 'pastWeeks':
            case 'futureWeeks': return parseWeek(date);
            case 'currentWeek': {
                const today = new Date();
                const isToday = date.getDate() === today.getDate() &&
                        date.getMonth() === today.getMonth() &&
                        date.getFullYear() === today.getFullYear();
                if (isToday) return 'Today';
                return parseDay(date);
            }
            default:
                return d3.timeFormat(date)('%x');
            }
        };

        const margin = { top: 40, right: 20, bottom: 50, left: 0 };

        // clear existing svg elements on update
        d3.selectAll('.calenderSvg g').remove();
        d3.selectAll('.calenderTooltipParentContainer tooltipContainer').remove();

        // base svg layer
        const svg = d3.select(d3Chart.current)
            .attr('width', width)
            .attr('height', (height) + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(0,' + margin.top + ')');

        // eventlistners for tooltip
        const mouseover = (event, d) => {
            setShowTooltip(1);
            setTooltipData({ type: d.type, data: d.eventDetails, top: (event.pageY), left: event.pageX });
        };

        const mouseout = (event, d) => {
            setShowTooltip(0);
            setTooltipData({ type: '', data: [], top: null, left: null });
        };

        const onclickHandler = (event, d, xType, yType, xDate) => {
            // event.stopPropagation();
            setIsModalOpen(true);
            setModalData({ type: d.type, data: d.eventDetails, xAxisType: xType, yAxisType: yType, xDate });
        };

        // Build Y scales and axis:
        const y = d3.scaleBand()
            .range([height, 0])
            .domain(yAxisDomain)
            .padding(0.2);
        svg.append('g')
            .call(d3.axisLeft(y))
            .call(g => g.select('.domain').remove())
            .selectAll('text')
            .style('font-size', '11px')
            .style('font-weight', 'bold');

        // Build X scale and axis:
        let prevGraphWidth = 0;
        for (const xscale in data.rows) {
            const xAxisDomain = data.rows[xscale];
            const graphWidth = xAxisDomain.length * cellWidth;

            // Build X scales and axis:
            const xAxisDict = d3.scaleBand()
                .range([0, graphWidth])
                .domain(xAxisDomain)
                .padding(0.3);

            svg.append('g')
                .attr('transform', 'translate(' + prevGraphWidth + ', 0)')
                .call(
                    d3.axisTop(xAxisDict)
                        .tickFormat(e => parseDate(e, xscale))
                )
                .call(g => g.select('.domain').remove())
                .selectAll('text')
                .style('font-size', '11px')
                .style('font-weight', 'bold')
                .attr('transform', 'rotate(-30)')
                .style('text-anchor', 'start');

            for (const dateObj of data.data[xscale]) {
                for (const _entity of yAxisDomain) {
                    const plot = dateObj.entity[_entity];
                    const xPos = xAxisDict(dateObj.date);
                    const yPos = y(_entity);
                    if (xPos && yPos) {
                        if (plot) {
                            svg.append('rect')
                                .attr('x', xPos + prevGraphWidth)
                                .attr('y', yPos)
                                .attr('width', xAxisDict.bandwidth())
                                .attr('height', y.bandwidth)
                                .attr('rx', 4)
                                .on('mouseover', (e) => mouseover(e, plot))
                                .on('mouseleave', mouseout)
                                .on('click', (e) => onclickHandler(e, plot, xscale, _entity, dateObj.date))
                                // .style('pointer-events', 'all')
                                .style('fill', '#216e39')
                                .style('opacity', plot.eventDetails.length * 0.5);
                        } else {
                            svg.append('rect')
                                .attr('x', xAxisDict(dateObj.date) + prevGraphWidth)
                                .attr('y', y(_entity))
                                .attr('width', xAxisDict.bandwidth())
                                .attr('height', y.bandwidth)
                                .attr('rx', 4)
                                .style('fill', '#ebedf0')
                                // .style("fill", 'white')
                                .style('stroke-width', 2);
                        }
                    } else {
                        console.error('data unavailable: ', dateObj.date, xPos, _entity, yPos, xscale);
                    }

                    // draw rect for today
                    if (xscale === 'currentWeek' && parseLocalDateString(new Date(dateObj.date)) === new Date().toLocaleDateString()) {
                        svg.append('rect')
                            .attr('x', xPos + prevGraphWidth - 3)
                            .attr('y', 0)
                            .attr('width', xAxisDict.bandwidth() + 7)
                            .attr('height', height)
                            .attr('rx', 4)
                            .attr('fill', 'none')
                            .style('stroke', 'green')
                            .style('stroke-width', '2')
                            .attr('opacity', 0.4);
                    }
                }
            }
            prevGraphWidth += graphWidth;
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            {isModalOpen ? <ModalContainer isOpen={isModalOpen} data={modalData} toggleModal={toggleModal} closeOnOutsideClick={true} /> : null}
            <div id='calenderChart' className='flex h-92' ref={wrapperRef}>
                <svg className="flex-no-shrink fill-current calenderSvg" ref={d3Chart} viewBox='0 0 1410 350' />
                <AnimatePresence>
                    <motion.div
                        animate={showTooltip ? 'open' : 'closed'}
                        variants={animateVariants}
                    >
                        <Tooltip
                            data={tooltipData} />
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    );
};

export default CalenderChart;
