import React from 'react';
import PatientTable from '../../tables/Patient';

const Provider = () => {
    return (
        <PatientTable />
    );
};

export default Provider;
