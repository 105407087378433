import { ACTION } from './action';

const initialState = {
    patient: {}
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION.ADD_PATIENT_STATE:
        return {
            ...state,
            patient: action.patient
        };
    case ACTION.UPDATE_PATIENT:
        return {
            ...state,
            patient: action.patient
        };
    case ACTION.DELETE_PATIENT:
        return {
            ...state,
            patient: {}
        };
    default:
        return state;
    }
};
