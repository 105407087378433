import { takeEvery, put } from 'redux-saga/effects';
import { ACTION } from './action';

function* addPatient ({ payload }) {
    yield put({
        type: ACTION.ADD_PATIENT_STATE, patient: payload
    });
}

export function* watchPatient () {
    yield takeEvery(ACTION.ADD_PATIENT, addPatient);
}
