import React from 'react';
import BloodGlucoseChart from '../../charts/BloodGlucoseChart';
import MoreOption from './MoreOption';

const BloodGlucoseBoard = (props) => {
    return (
        <div className='w-full'>
            <div className='flex flex-row justify-between'>
                <p className='text-lg font-medium'>Blood Glucose</p>
                <MoreOption />
            </div>
            <BloodGlucoseChart {...props} />
        </div>
    );
};

export default BloodGlucoseBoard;
