import axios from 'axios';

const getBaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
    case 'development': return 'https://api.herringbonehealth.com';
    case 'staging': return 'https://qa/';
    case 'production': return 'https://production';
    default: return 'http://localhost:3000';
    }
};

const axiosPublic = axios.create({
    baseURL: getBaseUrl()
});

const axiosPrivate = axios.create({
    baseURL: getBaseUrl(),
    Headers: {
        Authorization: 'Bearer accessToken'
    }
});

export { axiosPublic, axiosPrivate };
