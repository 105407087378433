import React, { useEffect, useState } from 'react';
import FormikControl from '../../components/formik/FormikControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileVideo, faImage, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

const Resources = ({ resources, cardsetId }) => {
    const [resourceStruct, setResourceStruct] = useState();
    useEffect(() => {
        setResourceStruct(() => resources.map(res => {
            return {
                label: res.fileName,
                value: res.resourceId,
                icon: GetIconFromType(res.fileType)
            };
        }));
    }, [resources]);

    return (
        <div key={cardsetId} className='flex w-full'>
            <FormikControl control='select' className='w-96' name="resourceIdSent" options={resourceStruct} defaultValue="add resources" isMulti={true} />
        </div>
    );
};

const GetIconFromType = (type) => {
    switch (type) {
    case 'pdf': return <FontAwesomeIcon icon={faFile} className='text-border p-2 rounded' />;
    case 'video': return <FontAwesomeIcon icon={faFileVideo} className='text-border p-2 rounded' />;
    case 'audio': return <FontAwesomeIcon icon={faVolumeHigh} className='text-border p-2 rounded' />;
    case 'image': return <FontAwesomeIcon icon={faImage} className='text-border p-2 rounded' />;
    default: return null;
    }
};

export default Resources;
