import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import CustomSelect from './CustomSelect';

const Select = (props) => {
    const { label, name, options, isMulti = false, defaultValue, ...rest } = props;
    return (
        <div className='form-control'>
            <label htmlFor={name}>{label}</label>
            <Field
                className="rounded-md border-2 border-border w-36" {...rest}
                as='select'
                id={name}
                name={name}
                options={options}
                component={CustomSelect}
                placeholder={defaultValue}
                isMulti={isMulti}
            />
            <ErrorMessage name={name}>
                <TextError />
            </ErrorMessage>
        </div>
    );
};

export default Select;
