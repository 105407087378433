import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Card from './Card';
import { ACTION } from '../../store/hhFramework/action';

const CardSet = ({ cardSet, cardSetIndex, patientId }) => {
    // const cardRefs = useRef([]);
    // const cardSetRef = useRef(null);

    const state = useSelector(state => state.hhFrameworkCardset);
    const dispatch = useDispatch();

    const [cardset, setCardset] = useState([]);
    const [flag, setFlag] = useState(true);

    // useEffect(() => {
    //     scrollCardToView(cardRefs);
    // }, [])
    useMemo(() => {
        setCardset(cardSet.attributes);
    }, [cardSet.id, flag]);

    // const scrollCardToView = (refs) => {
    //     if (!refs[cardSet.cardset_rank - 1]) return
    //     const gap = 2;
    //     const selectedEl = refs[cardSet.start_index - 1];
    //     const sidebar = cardSetRef.current;
    //     sidebar.scrollTop = 0;
    //     const scrolltop = (selectedEl.offsetTop - sidebar.offsetTop - sidebar.scrollTop) - gap;
    //     sidebar.scrollTop = scrolltop;
    // }

    const handleClick = () => {
        dispatch({
            type: ACTION.INITIATE_PATH,
            payload: {
                state,
                _travelPath: [`${cardSet.id}`],
                _fromIndex: cardSetIndex,
                _cardsetId: cardSet.id,
                patientId
            }
        });
    };

    const statusUpdateHandler = (cardIndex, cardId, status) => {
        if (cardset[cardIndex].id === cardId) {
            cardset[cardIndex].status = status;
        }
        setFlag(!flag);
    };

    return (
        <div className='flex flex-col w-2/5 p-4 mb-4 '>
            <div className='p-4 bg-primary rounded-t-lg flex flex-row justify-between' onClick={() => handleClick()}>
                <div className='flex flex-row items-center'>
                    <p className="text-md font-bold text-white">{cardSet.name}</p>
                </div>
            </div>
            <div className="flex-none w-full h-[30rem] overflow-y-scroll md:pb-4 shadow-md border-2 border-[#E8E8E8] rounded-b-lg ">
                <div className='w-[23.5rem] items-center py-4 flex flex-col'>
                    {
                        cardset.map((card, i) =>
                            <div key={`card_${i}`} className="w-[90%]" >
                                <Card id={i} cardIndex={i} cardsetName={cardSet.name} cardsetId={cardSet.id} cardDetails={card} patientId={patientId} handleStatusUpdate={statusUpdateHandler} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default CardSet;
