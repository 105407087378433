import { ACTION } from './action';

const initialState = {
    providerId: null,
    transactions: {
        status: {},
        formAttr: {}
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION.ADD_PROVIDER_ID:
        return {
            ...state,
            providerId: action.providerId
        };
    case ACTION.ADD_TRANSACTION_STATE:
        return {
            ...state,
            transactions: action.transactions
        };
    case ACTION.RESET_TRANSACTIONS:
        return {
            ...state,
            transactions: {
                status: {},
                formAttr: {}
            }
        };
    default:
        return state;
    }
};
