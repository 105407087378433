import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, toggleModal, closeOnOutsideClick, children }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeOnOutsideClick && modalRef.current && !modalRef.current.contains(event.target)) {
                toggleModal();
            }
        };
        document.body.style.overflow = 'hidden';
        // Bind the event listener
        document.getElementById('portal-modal').addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener
            document.body.style.overflow = 'unset';
            document.getElementById('portal-modal').removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef, closeOnOutsideClick, toggleModal]);

    return ReactDOM.createPortal(
        <div className="modal fixed flex justify-center items-center z-[1000] left-0 top-0 w-full h-[100%] bg-black/[0.4] overflow-hidden" style={isOpen ? { display: 'flex' } : null}>
            <div className="modal__wrapper fixed top-1/2 max-h-[90%] bg-white text-center z-[1000] -translate-y-1/2 p-4 rounded-md  flex" ref={modalRef}>
                {children}
            </div>
        </div>,
        document.getElementById('portal-modal')
    );
};

export default Modal;
