import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { axiosPublic } from '../../api';

const BloodGlucoseChart = () => {
    const { patient } = useSelector(state => state.patient);

    const d3Chart = useRef();
    const wrapperRef = useRef();

    useMemo(() => {
        if (patient.patient_id) {
            getData(patient.patient_id)
                .then(res => {
                    plotGlucoseGraph(res);
                });
        }
    }, [patient]);

    const plotGlucoseGraph = (data) => {
        const height = 390;
        const width = 1190;
        const parseMonth = d3.timeFormat("%d %b '%y");
        const glucoseData = data.presentData.blood_glucose_readings;
        const pastData = data.pastData.data;
        const xScale = data.presentData.reading_sessions;

        const colors = (n) => d3.schemeSet1[n];
        const margin = { top: 40, right: 20, bottom: 50, left: 70 };
        const slots = [null, ...xScale, ''];
        for (const set of pastData) {
            slots.splice(1, 0, parseMonth(new Date(set.month)));
        }
        const parseDate = (date) => {
            return d3.timeFormat('%d %b %Y')(new Date(date));
        };

        // clear existing svg elements on update
        d3.selectAll('.bloodGlucoseSvg g').remove();
        d3.selectAll('.tooltip').remove();

        // base svg layer
        const svg = d3.select(d3Chart.current)
            .attr('width', width)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const startHour = new Date();
        startHour.setHours(8, 0, 0, 0);
        const endHour = new Date();
        endHour.setHours(20, 0, 0, 0);
        const ticksData = [0, 54, 70, 180, 250, 450];
        const tickColorPalete = ['rgb(220 38 38)', 'rgb(248 113 113)', 'rgb(74 222 128)', 'rgb(250 204 21)', 'rgb(251 146 60)'];

        const xlinearScale = d3.scalePoint()
            .domain(slots)
            .range([0, width]);

        // draw X axis
        svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(
                d3.axisBottom(xlinearScale)
            )
            .style('font-size', '15px')
            .style('font-weight', 'bold');

        const y = d3.scaleLinear()
            .domain([0, 450])
            .range([height, 0]);

        // draw Y axis
        svg.append('g')
            .call(d3.axisLeft(y)
                .tickSize(-width)
                .tickPadding(10)
                .tickValues(ticksData)
            )
            .style('font-size', '15px')
            .style('font-weight', 'bold');

        // Y axis text
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0 - margin.left)
            .attr('x', 0 - (height / 2))
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .text('Glucose Level (mg/dL)');

        // fill background with colors
        for (let i = 0; i < ticksData.length; i++) {
            const color = tickColorPalete[i - 1];
            const _height = y(ticksData[i] - ticksData[i - 1]);
            const _y = y(ticksData[i]);
            svg.append('rect')
                .attr('x', 0)
                .attr('y', _y)
                .attr('width', width)
                .attr('height', height - _height)
                .attr('fill', color)
                .attr('opacity', 0.5);
        }

        // plot past positions
        for (const set of pastData) {
            const plotData = getBoxAndWhiskerPlotData(set.glucoseReadings);
            // eventlistners for tooltip
            const boxplotMouseover = (event, d) => {
                const hoverData = groupBySessionAndDate(d.glucoseReadings);
                let reading = '';
                for (const day in hoverData) {
                    reading += `<div style="padding: 10px"><table>
                        <tr><td>${parseDate(day)}</td></tr>
                        <tr><td style="text-align: left">Before Breakfast: </td><td style="text-align: left">${hoverData[day]['Before Breakfast'] ? hoverData[day]['Before Breakfast'][0].reading : '-'}</td></tr>
                        <tr><td style="text-align: left">Before Lunch: </td><td style="text-align: left">${hoverData[day]['Before Lunch'] ? hoverData[day]['Before Lunch'][0].reading : '-'}</td></tr>
                        <tr><td style="text-align: left">Before Dinner: </td><td style="text-align: left">${hoverData[day]['Before Dinner'] ? hoverData[day]['Before Dinner'][0].reading : '-'}</td></tr>
                        <tr><td style="text-align: left">Before Bed: </td><td style="text-align: left">${hoverData[day]['Before Bed'] ? hoverData[day]['Before Bed'][0].reading : '-'}</td></tr>
                    </table></div>`;
                }
                // for (let r = 0; r < d.glucose_reading.length; r++) {
                //     reading += "<li>" + d.glucose_reading[r] + " mg/dL</li>"
                // }
                div.transition()
                    .duration(200)
                    .style('opacity', 1);
                div.html(
                    `<div style="font-size: 14px">${parseMonth(new Date(d.month))}</div><div style="display: flex; flex-direction: row;">${reading
                    } </div>`
                )
                    .style('left', (event.pageX) + 'px')
                    .style('top', (event.pageY - 28) + 'px')
                    .style('color', 'white');
            };
            const boxplotMouseout = (event, d) => {
                div.transition()
                    .duration(500)
                    .style('opacity', 0);
            };
            const boxplotGroup = svg
                .append('g')
                .on('mouseleave', boxplotMouseout)
                .on('mouseover', (e) => boxplotMouseover(e, set));
            if (plotData) {
                const line1 = [{
                    x: parseMonth(new Date(set.month)),
                    y: plotData.upperQuartile
                }, {
                    x: parseMonth(new Date(set.month)),
                    y: plotData.upperExtreme
                }, {
                    x: parseMonth(new Date(set.month)),
                    y: plotData.lowerQuartile
                }, {
                    x: parseMonth(new Date(set.month)),
                    y: plotData.lowerExtreme
                }];
                const medianLine = [{
                    x: xlinearScale(parseMonth(new Date(set.month))) - 20,
                    y: y(plotData.median)
                }, {
                    x: xlinearScale(parseMonth(new Date(set.month))) + 20,
                    y: y(plotData.median)
                }];
                boxplotGroup.append('path')
                    .attr('d', d3.line()
                        .x(function (d) { return xlinearScale(d.x); })
                        .y(function (d) { return y(d.y); })
                        .curve(d3.curveLinear)(line1))
                    .attr('stroke', '#4A9BD5')
                    .attr('stroke-width', 2)
                    .attr('fill', 'none');
                boxplotGroup.append('rect')
                    .attr('x', xlinearScale(parseMonth(new Date(set.month))) - 20)
                    .attr('y', y(plotData.upperQuartile))
                    .attr('width', 40)
                    .attr('height', y(plotData.lowerQuartile) - y(plotData.upperQuartile))
                    .attr('fill', '#F87171')
                    .attr('opacity', 1);
                boxplotGroup.append('path')
                    .attr('d', d3.line()
                        .x(function (d) { return d.x; })
                        .y(function (d) { return d.y; })
                        .curve(d3.curveLinear)(medianLine))
                    .attr('stroke', '#4A9BD5')
                    .attr('stroke-width', 2)
                    .attr('fill', 'none');
            }
        }

        // tooltip position
        const div = d3.select('#bloodGlc')
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('position', 'absolute');

        // glucoseData.forEach((data, i) => {
        //     svg.append("text")
        //         .attr("transform",
        //             "translate(" + (width + margin.right - (i * 100)) + " ," +
        //             ((margin.top)) + ")")
        //         .style("text-anchor", "middle")
        //         .attr("fill", colors(i))
        //         .attr("background-color", "white")
        //         .text(data.days_from_appointment)
        // })

        // for (const group in glucoseData[0]) {
        // background for legend
        svg.append('rect')
            .attr('x', (width - (3 * 20)) - 90)
            .attr('y', 25)
            .attr('width', 135)
            .attr('height', 25 * glucoseData.length)
            .attr('fill', 'white');
        glucoseData.forEach((group, i) => {
            const data = group.day_readings.filter(obj => obj.blood_glucose_reading !== null);
            // draw line
            svg.append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke', colors(i))
                .attr('stroke-width', 3)
                .attr('d', d3.line()
                    .x(function (d, i) {
                        return xlinearScale(d.time_of_reading);
                    })
                    .y(function (d) {
                        return y(d.blood_glucose_reading);
                    }
                    )
                    .curve(d3.curveMonotoneX)
                );

            // draw legends
            const legendX = (width - (3 * 20));
            const legendY = ((i) * 25);
            svg.append('rect')
                .attr('x', legendX - 80)
                .attr('y', legendY + 30)
                .attr('width', 12)
                .attr('height', 12)
                .attr('fill', colors(i));
            // .attr("opacity", 0.5)
            svg.append('text')
                .attr('transform',
                    'translate(' + (legendX - 10) + ' ,' +
                    // "translate(" + (width - (3 * 320) + (i * 200)) + " ," +
                    (legendY + 43) + ')')
                .style('text-anchor', 'middle')
                .style('font-weight', 'bold')
                .attr('fill', colors(i))
                .attr('background-color', 'white')
                .text(parseDate(group.reading_date));

            // eventlistners for tooltip
            const mouseover = (event, d) => {
                div.transition()
                    .duration(200)
                    .style('opacity', 1);
                div.html(`${d.time_of_reading} </br> ${d.blood_glucose_reading} mg/dL`)
                    .style('left', (event.pageX) + 'px')
                    .style('top', (event.pageY - 28) + 'px')
                    .style('color', 'white');
            };
            const mouseout = (event, d) => {
                div.transition()
                    .duration(500)
                    .style('opacity', 0);
            };

            // draw circles on points
            svg.selectAll('myCircles')
                .data(data)
                .enter()
                .append('circle')
                .attr('fill', 'black')
                .attr('stroke', 'none')
                .attr('cx', function (d, i) {
                    return xlinearScale(d.time_of_reading);
                })
                .attr('cy', function (d) {
                    return y(d.blood_glucose_reading);
                }
                )
                .attr('r', 4)
                .on('mouseleave', mouseout)
                .on('mouseover', mouseover);
        });
    };

    function getBoxAndWhiskerPlotData (arr) {
        // const sortedData = arr.sort(function (a, b) { return a.glucose_reading - b.glucose_reading });
        const sortedData = arr.map(function (item) {
            return item.reading;
        }).sort(function (a, b) {
            return a - b;
        });
        const arrLen = sortedData.length;
        const dataPoints = {};
        dataPoints.upperExtreme = sortedData[arrLen - 1];
        dataPoints.lowerExtreme = sortedData[0];
        if (arrLen % 2 === 0) {
            const upperIndex = arrLen / 2;
            const lowerIndex = arrLen / 2 - 1;
            dataPoints.median = (sortedData[upperIndex] + sortedData[lowerIndex]) / 2;
            const lowerArr = sortedData.slice(0, lowerIndex + 1);
            const upperArr = sortedData.slice(upperIndex, arrLen);
            if (lowerArr.length % 2 === 0) {
                dataPoints.lowerQuartile = (lowerArr[(lowerArr.length / 2) - 1] + lowerArr[lowerArr.length / 2]) / 2;
                dataPoints.upperQuartile = (upperArr[(upperArr.length / 2) - 1] + upperArr[upperArr.length / 2]) / 2;
            } else {
                dataPoints.lowerQuartile = lowerArr[Math.ceil(lowerArr.length / 2)];
                dataPoints.upperQuartile = upperArr[Math.ceil(upperArr.length / 2)];
            }
        } else {
            dataPoints.median = sortedData[Math.ceil(arrLen / 2) - 1];
            const upperIndex = Math.floor(arrLen / 2);
            const lowerIndex = Math.floor(arrLen / 2);
            const lowerArr = sortedData.slice(0, lowerIndex);
            const upperArr = sortedData.slice(upperIndex + 1, arrLen);
            if (lowerArr.length % 2 === 0) {
                dataPoints.lowerQuartile = (lowerArr[(lowerArr.length / 2) - 1] + lowerArr[lowerArr.length / 2]) / 2;
                dataPoints.upperQuartile = (upperArr[(upperArr.length / 2) - 1] + upperArr[upperArr.length / 2]) / 2;
            } else {
                dataPoints.lowerQuartile = lowerArr[Math.ceil(lowerArr.length / 2)];
                dataPoints.upperQuartile = upperArr[Math.ceil(upperArr.length / 2)];
            }
        }
        return dataPoints;
    }

    function getData (id) {
        return axiosPublic.get(`/patient-bg-reading/${id}?set_id=latest`)
            .then(response => {
                const glucoseData = response.data;
                return Promise.resolve(glucoseData);
            })
            .then(prevRes => {
                return axiosPublic.get(`/patient-bg-reading-history/${id}?from_date=latest`)
                    .then(response => {
                        const pastData = response.data;
                        return Promise.resolve({
                            pastData,
                            presentData: prevRes
                        });
                    });
            });
    }

    function groupBySessionAndDate (data) {
        const groupedData = {};
        data.forEach(item => {
            if (!groupedData[item.date]) {
                groupedData[item.date] = {};
            }
            if (!groupedData[item.date][item.session]) {
                groupedData[item.date][item.session] = [];
            }
            groupedData[item.date][item.session].push(item);
        });
        return groupedData;
    }

    return (
        <div id='bloodGlc' className='flex h-92 ' ref={wrapperRef}>
            <svg className="flex-no-shrink fill-current bloodGlucoseSvg" ref={d3Chart} viewBox='0 0 1250 690' />
        </div>
    );
};

export default BloodGlucoseChart;
