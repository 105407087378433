import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { axiosPublic } from '../../api';

const PatientTable = () => {
    const navigate = useNavigate();

    const [patients, setPatients] = useState([]);
    useEffect(() => {
        getPatientDetails();
    }, []);

    const getPatientDetails = () => {
        return axiosPublic.get('/patient-list')
            .then(res => {
                setPatients(res.data);
            });
    };

    const patientViewhandler = (patient) => {
        // TODO: remove plural
        navigate(
            `/provider/patient/${patient.patient_id}`,
            {
                state: patient
            }
        );
    };

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="min-w-full">
                            <thead className="border-b bg-[#20354b]">
                                <tr>
                                    <th scope="col" className="text-md font-medium text-white px-6 py-4 text-center">
                                        id
                                    </th>
                                    <th scope="col" className="text-md font-medium text-white px-6 py-4 text-center ">
                                        Name
                                    </th>
                                    <th scope="col" className="text-md font-medium text-white px-6 py-4 text-center">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    patients.length
                                        ? patients.map((patient, i) => {
                                            return (
                                                <tr className="border-b text-center" key={i}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {patient.patient_id}
                                                    </td>
                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {patient.patient_name}
                                                    </td>
                                                    <td className="text-sm flex flex-column justify-evenly text-gray-900 font-light  px-6 py-4 whitespace-nowrap">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => patientViewhandler(patient)}>View</button>
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Edit</button>
                                                        <button className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Delete</button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : <tr className="border-b" key={1}>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center bg-red-400">
                                                No records found
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientTable;
