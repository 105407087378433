import { combineReducers } from 'redux';
import { reducer as hhFramework } from './hhFramework/reducer';
import { reducer as hhFrameworkTrans } from './hhFrameworkTransaction/reducer';
import { reducer as patient } from './patient/reducer';
import { reducer as provider } from './provider/reducer';

export default combineReducers({
    hhFrameworkCardset: hhFramework,
    hhFrameworkTrans,
    patient,
    provider
});
